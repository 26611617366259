<template>
  <div class="py-4 container-fluid">
    <div id="kakaoTemplateCard" class="card mt-0">
      <div class="card-header">
        <h6 class="mb-0">카카오 템플릿 조회</h6>
      </div>
      <div class="card-body pt-0">
        <div class="row align-items-center mt-2">
          <div class="col-5 text-start">
            <select
              id="profileSelect"
              v-model="selectedProfile"
              class="form-control"
            >
            </select>
          </div>
          <div class="col-5 text-start">
            <select
              id="statusSelect"
              v-model="selectedStatus"
              class="form-control"
            >
            </select>
          </div>
          <div class="col-2 text-end">
            <material-button color="success" size="sm" variant="default" class="me-3" style="width: 120px;"
                             @click="getKakaoTemplatesDescription()">
              조회
            </material-button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-header">
      </div>
      <div class="card-body">
        <EasyDataTable
          table-class-name="customize-table"
          empty-message="조회된 내역이 없습니다."
          rows-per-page-message="페이지당 표시 행 수"
          rows-of-page-separator-message="중"
          :headers="tableColumns"
          :items="kakaoTemplates"
          :loading="isTemplateLoading"
          @click-row="showTemplateDetail"
        ></EasyDataTable>
      </div>
    </div>
    <div id="templateDetailModal" class="modal fade"  tabindex="-1" aria-labelledby="templateDetailModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="createGroupModalLabel" class="modal-title" >카카오 템플릿</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <material-input-vmodel
                    id="profileIdInput"
                    v-model="templateDetail.profile_id"
                    label="프로필@ID"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <div class="row">
                  <material-input-vmodel
                    id="templateNameInput"
                    v-model="templateDetail.template_name"
                    label="템플릿명"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <div class="row">
                  <material-input-vmodel
                    id="templateCodeInput"
                    v-model="templateDetail.template_id"
                    label="템플릿코드"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <div class="row">
                  <material-input-vmodel
                    id="templateStatus"
                    v-model="status_alias[templateDetail.template_status]"
                    label="상태"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <div v-if="templateDetail.param_count > 0" class="row">
                  <material-input-vmodel
                    id="paramCount"
                    v-model="templateDetail.param_count"
                    label="파라메터수"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <div v-if="templateDetail.param_count > 0" class="row">
                  <material-input-vmodel
                    id="paramList"
                    v-model="templateDetail.param_list"
                    label="파라메터리스트"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <!-- <div v-for="(param, index) in templateDetail.param_list" :key="index" class="row">
                  <material-input-vmodel
                    id="param"
                    :label="`파라메터 #${index}`"
                    variant="static"
                    :disabled="true"
                    :model-value="`${param}`"
                  >
                  </material-input-vmodel>
                </div> -->
                <div class="row">
                  <material-input-vmodel
                    id="emphasizeTypeInput"
                    v-model="emphasize_type[templateDetail.emphasize_type]"
                    label="강조 유형"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                <div v-if="templateDetail.title" class="row">
                  <material-input-vmodel
                    id="emphasizeTitleInput"
                    v-model="templateDetail.title"
                    label="강조 타이틀"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div> 
                <div v-if="templateDetail.subtitle" class="row">
                  <material-input-vmodel
                    id="emphasizeSubtitleInput"
                    v-model="templateDetail.subtitle"
                    label="강조 보조표기"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div> 
                <div v-if="templateDetail.image_name" class="row">
                  <material-input-vmodel
                    id="imageNameInput"
                    v-model="templateDetail.image_name"
                    label="이미지명"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>                
                <div v-if="templateDetail.image_url" class="row">
                  <material-input-vmodel
                    id="imageUrlInput"
                    v-model="templateDetail.image_url"
                    label="이미지URL"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
                
                <div class="row">
                  <material-input-vmodel
                    id="sendTypeInput"
                    v-model="templateDetail.send_type"
                    label="send_type"
                    variant="static"
                    :disabled="true"
                  >
                  </material-input-vmodel>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <span style="font-size: 0.9rem;">템플릿 내용</span>
                </div>
                <div class="row">
                  <material-text-area
                    id="templateContent"
                    v-model="templateDetail.template_content"
                    label="템플릿컨텐츠"
                    :disabled="true"
                    :style="{ minHeight: `${minHeightTemplate}px`, width: '100%' }"
                  >
                  </material-text-area>
                </div>
                <div v-if="templateDetail.comment.content" class="row">
                  <span style="font-size: 0.9rem;">카카오톡 검수결과</span>
                </div>
                <div v-if="templateDetail.comment.content" class="row">
                  <material-text-area
                    id="comment"
                    v-model="templateDetail.comment.content"
                    label="검수결과"
                    :disabled="true"
                    :style="{ minHeight: `${minHeightComment}px`, width: '100%' }"
                  >
                  </material-text-area>
                </div>
                <div v-if="templateDetail.button && templateDetail.button.length > 0" class="row">
                  <span style="font-size: 0.9rem;">버튼</span>
                </div>
                <div v-if="templateDetail.button && templateDetail.button.length > 0" class="row">
                  <material-text-area
                    id="buttonInput"
                    v-model="formattedButtonValue"
                    label="버튼"
                    :disabled="true"
                    :style="{ minHeight: `${minHeightButton}px`, width: '100%' }"
                  >
                  </material-text-area>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            >닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import { DataTable } from "simple-datatables";
import MaterialButton from "@/components/MaterialButton.vue";
import * as Choices from "choices.js";
import axios from 'axios';
import XLSX from 'xlsx/dist/xlsx.full.min.js';
import ApiCall from "@/Interface/ApiCall";
import MaterialTable from "@/components/MaterialTable.vue";
import MaterialPagination from "@/components/MaterialPagination.vue";
import MaterialInputVmodel from "@/components/MaterialInputVmodel.vue";
import MaterialTextArea from "@/components/MaterialTextarea.vue";

export default {
  name: "SearchKakaoTemplate",
  components: {
    MaterialInputVmodel,
    MaterialTextArea,
    MaterialButton,
  },
  data() {
    return {
      minHeightTemplate: 50,
      minHeightComment: 50,
      minHeightButton: 50,
      kakaoTemplates: [],
      kakaoTemplatesForTable: [],
      templateDetailModal: null,
      selectedProfile: '',
      selectedStatus: '',
      templateDetail: {
        template_content: '', // Ensure this is initialized as a string
        comment: {
          content: '', // Also initialized as a string for comment content
        },
        button: '',
      },
      profileSelectInstance: null,
      statusSelectInstance: null,
      kakaoProfiles: [],
      isTemplateLoading: false,
      isProfileLoading: false,
      //image_url, button, send_type, image_name
      tableColumns : [
        { text: "생성일", value: "create_timestamp", sortable: true},
        { text: "프로필", value: "profile_id", sortable: true},
        { text: "템플릿명", value: "template_name", sortable: true},
        { text: "템플릿코드", value: "template_id", sortable: true},
        { text: "템플릿 내용", value: "template_content", sortable: true},
        { text: "상태", value: "template_status_alias", sortable: true},
        { text: "사유", value: "comment.content", sortable: true},
      ],
      status_alias : {
        'Requested' : '등록',
        'Under Review' : '검수중',
        'Rejected' : '반려',
        'active' : '승인',
        'active-super' : '승인',
      },
      status_alias_for_select : {
        'Requested' : '등록',
        'Under Review' : '검수중',
        'Rejected' : '반려',
        'active' : '승인',
      },
      emphasize_type : {
        'NONE' : '선택안함',
        'TEXT' : '강조표기형',
        'IMAGE' : '이미지형',
        '' : '선택안함',
      },
    };
  },
  computed: {
    kakaoProfileOptions() {
      const initialData = { 'label': '전체', 'value': 'all' };
      const categoryArray = this.kakaoProfiles.map(item => ({
        'label': item.sk,
        'value': item.sender_key,
      }));
      return [initialData, ...categoryArray];
      // return [initialData];
    },
    templateStatusOptions() {
      const statusOptions = [
        { 'label': '전체', 'value': 'all' }
      ];

      for (const [value, label] of Object.entries(this.status_alias_for_select)) {
        statusOptions.push({ label, value });
      }

      return statusOptions;
    },
    formattedButtonValue() {
      return this.templateDetail.button && this.templateDetail.button.length
        ? this.templateDetail.button
            .map(btn =>
              Object.entries(btn)
                .map(([key, value]) => `${key}: ${value}`)
                .join('\n')
            )
            .join('\n\n')
        : 'No button data available';
    }
  },
  watch: {
    selectedProfile(newList) {
      if (newList !== '0') {
        console.log("selected : " + newList)
      }
    },
    "templateDetail.template_content"(newContent) {
      const content = typeof newContent === "string" ? newContent : "";
      const newlines = (content.match(/\n/g) || []).length + 1; // Use content here
      this.minHeightTemplate = Math.max(50, newlines * 22);
    },
    "templateDetail.comment.content"(newContent) {
      if (!this.templateDetail.comment) {
        console.warn("templateDetail.comment is undefined. Initializing...");
        this.templateDetail.comment = { content: "" }; // Reinitialize safely
        return;
      }
      const content = typeof newContent === "string" ? newContent : "";
      const newlines = (content.match(/\n/g) || []).length + 4; // Use content here
      this.minHeightComment = Math.max(50, newlines * 22);
    },
    "templateDetail.button"(newContent) {
      const content = typeof newContent === "string" ? newContent : "";
      const newlines = (content.match(/\n/g) || []).length + 1; // Use content here
      this.minHeightButton = Math.max(50, newlines * 22);
    },
  },
  mounted() {
    this.getKakaoProfiles();
    this.getKakaoTemplatesDescription();
    this.minHeight = Math.max(50, this.templateDetail.template_content.length * 2);
    this.minHeight = Math.max(50, this.templateDetail.comment.content.length * 2);
    this.minHeight = Math.max(50, this.templateDetail.button.length * 2);
  },
  methods: {
    initChoicesList() {
      if (this.profileSelectInstance) {
        this.profileSelectInstance.destroy();
      }
      this.profileSelectInstance = new Choices('#profileSelect', {
        shouldSort: false,
        choices: this.kakaoProfileOptions,
      });
    },
    initStatusChoicesList() {
      if (this.statusSelectInstance) {
        this.statusSelectInstance.destroy();
      }
      this.statusSelectInstance = new Choices('#statusSelect', {
        shouldSort: false,
        choices: this.templateStatusOptions,
      });
    },
    getKakaoProfiles() {
      this.isProfileLoading = true;
      ApiCall.call('user/kakaoprofile/profileinfo', 'GET').then(response =>{
        this.kakaoProfiles = response.data.list
        console.log("response :"+response.data.message);
        this.isProfileLoading = false;
        this.initChoicesList();
        this.initStatusChoicesList();
      }).catch(error => {
        console.error('Error: Unable to fetch data from API', error);
      });
    },
    getKakaoTemplatesDescription() {
      this.isTemplateLoading = true
      const getTemplatePayload = {
        send_type: "kakao",
      };

      if (this.selectedProfile !== 'all') {
        getTemplatePayload['from_kakao_channel'] = this.selectedProfile;
      }

      if (this.selectedStatus !== 'all') {
        getTemplatePayload['template_status'] = this.selectedStatus;
      }

      ApiCall.call('template/list', 'POST', getTemplatePayload)
        .then(response => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.kakaoTemplates = response.data;
            this.kakaoTemplates.sort((a,b) => {
              const dateA = new Date(a.create_timestamp);
              const dateB = new Date(b.create_timestamp);
              return dateB - dateA;
            })
            // 상태값 alias 추가
            this.kakaoTemplates = this.kakaoTemplates.map((obj) =>{
              return { ...obj, 'template_status_alias' : this.status_alias[obj['template_status']]}
            });
            this.isTemplateLoading = false
          } else {
            console.error('Item not found.');
            this.kakaoTemplates = [];
            this.isTemplateLoading = false
          }
        })
        .catch(error => {
          console.error('Error: Unable to fetch data from API', error);
        });
    },
    showTemplateDetail(item) {
      console.log("row clicked : " + JSON.stringify(item))
      this.templateDetail = {
      template_content: "",
        comment: {
          content: "",
        },
        button: "",
      };
      this.minHeightComment = 50;
      this.minHeightTemplate = 50;
      this.minHeightButton = 50;
      this.templateDetail = item
      const bootstrap = this.$store.state.bootstrap;
      // 모달 열기
      this.templateDetailModal = new bootstrap.Modal(
        document.getElementById("templateDetailModal")
      );
      this.templateDetailModal.show();
    }
  },
};
</script>

<style>
.my-input .form-control {
    height: 500px;
    resize: none;
    
}
.customize-table {
  table-layout: fixed;
  white-space: pre-wrap;
  width: 100%; /* Ensures the table spans the full width of its container */
}
.customize-table th:nth-child(3),
.customize-table td:nth-child(3) {
  min-width: 90px;
}
.customize-table th:nth-child(4),
.customize-table td:nth-child(4) {
  min-width: 100px;
}
.customize-table th:nth-child(5),
.customize-table td:nth-child(5) {
  min-width: 100px;
}
.customize-table th:nth-child(6),
.customize-table td:nth-child(6) {
  min-width: 60px;
}
.customize-table th:nth-child(7),
.customize-table td:nth-child(7) {
  min-width: 60px;
}
</style>
